/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */

export function property(profile, property) {
  if (!profile) return null;

  switch (property.toString().toUpperCase())
  {
    case 'NAME': return profile.name ?? profile.id ?? profile.uid ?? profile.type;
    case 'EXCERPT': return profile.c_excerpt ?? profile.c_rawContent;
    case 'URL': return profile.websiteUrl?.url ?? profile.website ?? profile.d_link;
    case 'AUTHOR': return profile.c_authorName;
    case 'CATEGORIES': return profile.c_pluggedInCategories;
    case 'IMAGE': return profile.c_image;
    case 'MOVIEGENRE': return profile.c_movieGenre;
    case 'MOVIESTATUS': return profile.c_movieStatus;
    case 'MOVIEMPAARATING': return profile.c_movieMPAARating;
    case 'DIRECTOR': return profile.c_director;
    case 'CAST': return profile.c_cast;
    case 'INTHEATERDATE': return profile.c_inTheaterDate;
    case 'KIDSCONTENTCAUTION': return profile.c_kidsContentCaution;
    case 'TEENSCONTENTCAUTION': return profile.c_teensContentCaution;
    case 'ADULTSCONTENTCAUTION': return profile.c_adultsContentCaution;
    case 'TVGENRE': return profile.c_tVGenre;    
    case 'TVRATING': return profile.c_tVRating;
    case 'NETWORK': return profile.c_network;
    case 'MUSICGENRE': return profile.c_musicGenre;
    case 'PERFORMANCE': return profile.c_trackPerformance;
    case 'RELEASEDATE': return profile.c_releaseDate;    
    case 'RECORDLABEL': return profile.c_recordLabel; 
    case 'BOOKGENRE': return profile.c_bookGenre; 
    case 'AGERANGE': return profile.c_ageRange; 
    case 'PUBLISHER': return profile.c_publisher; 
    case 'YEARPUBLISHED': return profile.c_yearPublished; 
    case 'AWARDS': return profile.c_awards; 
    case 'LENGTH': return profile.c_length;
    case 'GAMEGENRE': return profile.c_gameGenre;    
    case 'ESRBRATING': return profile.c_gameESRBRating;    
    case 'PLATFORM': return profile.c_gamePlatform;    
    case 'YOUTUBEGENRE': return profile.c_youtubeGenre;   
    case 'DATE': return profile.c_date;   
    case 'TYPE': return profile.type;   
    default: return profile[property];
  }
}

export function type(input) {
   if (!input) return null;

  input = input.substring(3);
  return input;
}

export function date(input) {
   if (!input) return null;

    try {
      const date = new Date(input);

      return `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()}`;
    } catch {
      console.log();
      return null;
    }
}

export function imageWithFallback(image) {
  if (image) {
    return Formatter.image(image, "800x1", true).url;
  } else {
    return "/static/assets/images/logo.webp";
  }
}

export function text(input, wordLimit) {
  if (!input) return "";
  if (!wordLimit) return input ?? "";

  let result = "";
  let words = input?.split(" ") ?? [];
  for (let i = 0; i < Math.min(words.length, wordLimit); i++) {
    result += words[i] + " ";
    if (i >= wordLimit - 1) {
      result += " ...";
    }
  }

  return result;
}